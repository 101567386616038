import {
  buildReadingRoute,
  BuildReadingRouteParams,
} from '@customer-frontend/education';

export const routes = {
  termsAndConditions: '/terms-and-conditions',
  privacyPolicy: '/privacy-policy',
  test_secure: '/react-test/secure',
  test_public: '/react-test/public',
  login: '/login',
  signup: '/signup',
  forgotPassword: '/forgot-password',
  reset: '/reset',
  completeSignup: '/completeSignup',
  profile: '/profile',
  profileConfirmPaymentDetails: '/profile/confirm-payment-details',
  lastLonger: {
    root: '/last-longer',
    home: '/last-longer/home',
    class: (id: string): string => `/last-longer/class/${id}`,
  },
  start: {
    root: '/start',
    problemSelect: '/start/problem-select',
    howItWorks: '/start/how-it-works',
    signup: '/start/signup',
    deny: '/start/deny',
    continue: '/start/continue',
  },
  consultation: {
    root: (id: string): string => `/consultation/${id}`,
    quiz: (id: string): string => `/consultation/${id}/quiz`,
    payment: (
      id: string,
      options?: {
        couponCode?: string | null;
      },
    ): string =>
      `/consultation/${id}/payment${
        options?.couponCode ? `?couponCode=${options.couponCode}` : ``
      }`,
    plan: (id: string): string => `/consultation/${id}/plan`,
    safetyInfo: (id: string): string => `/consultation/${id}/pharmacy-info`,
    review: (id: string): string => `/consultation/${id}/review`,
    followUp: (id: string): string => `/consultation/${id}/follow-up`,
    collectMedicare: (id: string): string =>
      `/consultation/${id}/collect-medicare`,
    phoneCall: (id: string): string => `/consultation/${id}/phone-call`,
    scheduleCall: (id: string): string => `/consultation/${id}/schedule-call`,
    chat: (id: string, loadAtTop?: boolean): string =>
      `/consultation/${id}/chat${loadAtTop ? '?load-at-top=true' : ''}`,
    collectAddress: (id: string): string =>
      `/consultation/${id}/collect-address`,
    confirmAnswers: (id: string): string =>
      `/consultation/${id}/confirm-answers`,
    createAccount: (id: string): string => `/consultation/${id}/create-account`,
    consultPay: (id: string): string => `/consultation/${id}/consult-pay`,
    confirmation: (id: string): string => `/consultation/${id}/confirmation`,
    paymentConfirm: (id: string): string =>
      `/consultation/${id}/payment-confirm`,
    confirmPathology: (id: string): string =>
      `/consultation/${id}/confirm-pathology`,
    confirmPaymentDetails: (id: string): string =>
      `/consultation/${id}/confirm-payment-details`,
    confirmOrderPaymentDetails: (id: string): string =>
      `/consultation/${id}/confirm-order-payment-details`,
    orderConfirmed: (id: string): string =>
      `/consultation/${id}/order-confirmed`,
    doctorsNote: (id: string): string => `/consultation/${id}/doctors-note`,
    result: (id: string): string => `/consultation/${id}/results`,
    shipping: (id: string): string => `/consultation/${id}/shipping`,
    testResults: (id: string): string => `/consultation/${id}/testResults`,
    medicationPreference: (id: string): string =>
      `/consultation/${id}/medication-preference`,
    addons: (id: string): string => `/consultation/${id}/add`,
    paymentOptions: (id: string): string =>
      `/consultation/${id}/payment-options`,
    reviewSubstitution: (id: string): string =>
      `/consultation/${id}/review-substitution`,
    confirmSubstitution: (id: string): string =>
      `/consultation/${id}/confirm-substitution`,
    reviewFollowUpOrReview: (id: string): string =>
      `/consultation/${id}/review-follow-up-or-review`,
    confirmFollowUpOrReview: (id: string): string =>
      `/consultation/${id}/confirm-follow-up-or-review`,
    activatePurchaseFlow: {
      review: (id: string): string =>
        `/consultation/${id}/activate-purchase/review`,
      tierSelection: (id: string): string =>
        `/consultation/${id}/activate-purchase/tier-selection`,
      addons: (id: string): string =>
        `/consultation/${id}/activate-purchase/add-ons`,
      addDetails: (id: string): string =>
        `/consultation/${id}/activate-purchase/add-details`,
      confirm: (id: string): string =>
        `/consultation/${id}/activate-purchase/confirm`,
      receipt: (id: string): string =>
        `/consultation/${id}/activate-purchase/receipt`,
    },
    phoneCallConfirmed: (id: string): string => `/call/${id}/confirmation`,
  },
  quiz: {
    root: (id: string): string => `/quiz/${id}`,
    summary: (id: string): string => `/quiz/${id}/summary`,
    rejected: (id: string): string => `/quiz/${id}/rejected`,
    general: {
      start: (quizCode: string): string => `/quiz/start/${quizCode}`,
      complete: (quizCode: string): string => `/quiz/complete/${quizCode}`,
    },
  },
  weightLoss: {
    confirmation: '/weight-loss/confirmation',
    dashboard: '/weight-loss/dashboard',
    faq: '/weight-loss/faq',
    learn: '/weight-loss/learn',
  },
  handlePurchaseOfferingsIntent: '/handle-purchase-offerings-intent',
  profileSubscriptions: '/profile/subscriptions',
  profileMedicare: '/profile/medicare',
  alternativeMedicationsFaq: '/profile/alternative-medications',
  saxendaDosageCapFaq: '/profile/saxenda-dosage-cap',
  subscriptionV2: {
    payment: (id: string): string => `/subscription-plan/${id}/payment`,
    confirm: (id: string): string => `/subscription-plan/${id}/confirm`,
    manage: (id: string): string => `/subscription/${id}`,
    cancelled: '/subscriptions/cancelled',
    upgrade: '/subscriptions/upgrade',
    upgradeSuccess: '/subscriptions/upgrade-success',
  },
  education: {
    reading: (id: string, params?: BuildReadingRouteParams): string =>
      `${buildReadingRoute(id, params)}`,
  },
  treatment: {
    help: (treatmentId: string) => `/treatment/${treatmentId}/help`,
    plan: (treatmentId: string) => `/treatment/${treatmentId}/plan`,
    practitionerLetter: (treatmentId: string) =>
      `/treatment/${treatmentId}/clinical/practitioner`,
    pharmacy: (treatmentId: string) =>
      `/treatment/${treatmentId}/clinical/pharmacy`,
  },
  offering: {
    // These routes are based on purchaseId, but avoid the term 'purchase' in the customer facing URLs,
    // as it's too transactional/commercial for a medical setting
    plan: (purchaseId: string): string => `/offering/${purchaseId}/plan`,
    notes: (purchaseId: string): string => `/offering/${purchaseId}/notes`,
    faq: (purchaseId: string): string => `/offering/${purchaseId}/faq`,
    switch: (purchaseId: string): string => `/offering/${purchaseId}/switch`,
    switchReceipt: (purchaseId: string): string =>
      `/offering/${purchaseId}/switch-receipt`,
  },
};
